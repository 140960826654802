<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">For the following reaction:</p>

      <p class="mb-5 pl-14">
        <stemble-latex :content="`$\\ce{${coefA}A(g) + ${molB.toInt()}B(g) -> ${coefC}C(g)}$`" />
      </p>

      <p class="mb-5">
        If the <b>rate of formation</b> of product
        <stemble-latex content="$\text{C}$" />
        is measured at
        <number-value :value="rateC" unit="\text{M/s,}" />
        calculate the <b>relative rates of consumption</b>
        for reagents
        <stemble-latex content="$\text{A}$" />
        and
        <stemble-latex content="$\text{B.}$" />
      </p>

      <calculation-input
        v-model="inputs.rateA"
        class="mb-3"
        prepend-text="$\text{Rate of consumption of A:}$"
        append-text="$\text{M/s}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.rateB"
        class="mb-3"
        prepend-text="$\text{Rate of consumption of B:}$"
        append-text="$\text{M/s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question365',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rateA: null,
        rateB: null,
      },
    };
  },
  computed: {
    molA() {
      // Range is 1-3
      return this.taskState.getValueBySymbol('molA').content;
    },
    coefA() {
      if (this.molA.toInt() === 1) {
        return '';
      } else {
        return this.molA.toInt();
      }
    },
    molB() {
      // Range is 2-3
      return this.taskState.getValueBySymbol('molB').content;
    },
    molC() {
      // Range is 1-3
      return this.taskState.getValueBySymbol('molC').content;
    },
    coefC() {
      if (this.molC.value === 1) {
        return '';
      } else {
        return this.molC.toInt();
      }
    },
    rateC() {
      return this.taskState.getValueBySymbol('rateC').content;
    },
  },
};
</script>
